$blue: hsl(206, 72%, 68%);
$green: hsl(120, 42%, 54%);
$white: hsl(0, 0%, 100%);
$grey: hsl(226, 10%, 55%);
$disa: hsl(226, 15%, 83%);

.MockupGeneratorContainer {
    .design-section {
        min-height: 400px;
    }
    &.newVersion {
        .TabContent {
            padding: 0 !important;
        }
        .Step2Container {
            .ListProductLineConfirm {
                // height: calc(100vh - 200px);
            }
            .PrintFileRequirement {
                padding: 0 28px;
            }
        }
        .NavBar2 {
            padding: 16px 20px 16px 32px;
        }
    }
    .design-section-design-view {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 690px;
        margin: auto;
    }

    .mockup-container {
        .Polaris-Card {
            border-radius: 3px;
            box-shadow: 0 1px 10px rgba(44, 42, 68, 0.12) !important;

            .Polaris-Card__Header {
                padding: 1.25rem 1.25rem 0 1.25rem;
            }

            .Polaris-Tabs__Title {
                font-weight: bolder;
            }
        }
    }

    .moveable-container {
        overflow: hidden;
    }

    .moveable-area {
        cursor: all-scroll;
    }

    .mockup-image-preview:hover {
        border: blue 5px;

        .frame-wrapper {
            display: block;
        }
    }

    .mockup-image-preview {
        //overflow: hidden;
        width: 8rem;
        position: relative;

        .artwork-wrapper {
            position: absolute;
            display: block;
            overflow: hidden;
            top: 2.45rem;
            width: 100%;
        }

        .frame-wrapper {
            display: block;
            //border: 2px solid #D3DCD7;
        }

        .hover-resize:hover {
            box-shadow: 0 0 13px 7px #999;
            border-radius: 50%;
        }

        .background-wrapper {
            position: absolute;
            display: block;
            overflow: hidden;
            width: 100%;
        }
    }

    .design-wapper-4 {
        .position-button-area {
            .Polaris-ButtonGroup {
                flex-wrap: wrap;
            }
        }
    }

    .position-button-area {
        .Polaris-ButtonGroup {
            flex-wrap: nowrap;
            justify-content: center;
        }

        .Polaris-Button {
            width: 40px;
            height: 40px;
            border-radius: 0;
        }

        .button-align {
            width: 22px;
            height: 22px;
        }

        .Polaris-Icon {
            width: 15px;
            height: 15px;
        }
    }

    .align-left {
        color: black;
        border: 1px solid white;
        border-left: 1px solid #637381;
    }

    .align-right {
        border: 1px solid white;
        color: black;
        border-right: 1px solid #637381;
    }

    .align-top {
        border: 1px solid white;
        color: black;
        border-top: 1px solid #637381;
    }

    .align-bottom {
        border: 1px solid white;
        color: black;
        border-bottom: 1px solid #637381;
    }

    .align-horizon-right {
        border: 1px solid white;
        color: black;
        border-right: 1px solid #637381;
    }

    .align-horizon-left {
        border: 1px solid white;
        color: black;
    }

    .align-horizon-up {
        border: 1px solid white;
        color: black;
    }

    .align-horizon-down {
        border: 1px solid white;
        color: black;
        border-bottom: 1px solid #637381;
        width: 10px;
    }

    .align-middle {
        .Polaris-Icon {
            width: 10px;
            height: 10px;
        }
    }

    .align-fill-right {
        color: black;
    }

    .align-fill-left {
        color: black;
    }

    .align-fill {
        border: 1px solid #637381;
    }

    .align-fit-up {
        border: 1px solid #637381;
        color: black;
        border-bottom: 0;
        width: 10px;
    }

    .align-fit-down {
        border: 1px solid #637381;
        color: black;
        border-top: 0;
        width: 10px;
    }

    .SwitchBetweenSides {
        // padding: 0 12px;
        .Sides {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: fit-content;
            max-width: 750px;
            @media (max-width: 1200px) {
                max-width: 600px;
            }
        }

        button.Side {
            background-color: #fafbfa;
            border: 1px solid #e9eeec;
            color: #6e7370;
            border-radius: 4px;
            line-height: 1;
            padding: 10px 13px;
            outline: none;
            margin-bottom: 8px;

            &:not(:last-child) {
                margin-right: 8px;
            }
            &.invalidDpi {
                color: #fff;
                background-color: #dc3545;
                border-color: #dc3545;
            }
            &.active {
                color: #fff;
                background-color: #007bff;
                border-color: #007bff;
            }

            &:hover:not(.active) {
                opacity: 0.8;
            }
        }
    }

    .MockupEditor,
    .MockupAllAreas {
        .AllAreaStage {
            // height: calc(100vh - 138px);
        }
        .SwitchBetweenSides {
            width: max-content;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
        }
        button.Side {
            background-color: #ffffff;
            padding: 6px 8px;
            text-transform: capitalize;
            &.AllAreas {
                min-width: 85px;
            }
        }
    }

    .SwitchBetweenDesignAndPreview {
        > button {
            font-size: 14px;
            font-weight: 700;
            color: #6e7370;
            background-color: #fff;
            outline: none;
            line-height: 1;
            padding: 10px 26px;
            border: 1px solid #e9eeec;
            transition: all 0.3s;

            &.Design {
                border-radius: 4px 0 0 4px;
            }

            &.Preview {
                border-radius: 0 4px 4px 0;
                margin-left: -1px;
            }

            &.active {
                background-color: #007bff;
                color: #fff;
                border-color: #007bff;
            }

            &:hover:not(.active) {
                background-color: darken(#ffffff, 5);
            }

            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    .EditorToolbarContainer {
        font-size: 12px;
        span {
            text-align: center;
        }
        .Option {
            border: 1px solid #e9eeec;
            border-radius: 4px;
            padding: 6px 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &:hover {
                background-color: darken(#ffffff, 5);
            }
        }
    }

    .UploadContainer {
        font-size: 14px;
        overflow: auto;
        height: calc(100vh - 70px);
        &.NewVersion {
            display: flex;
            flex-direction: column;
            .CampaignDesignContainer,
            .CampaignDetailsContainer,
            .MockupImagePreviewContainer {
                overflow: auto;
                max-height: calc(100vh - 138px);
            }
            .MockupGeneratorPublish {
                height: 60px;
                padding: 15px 20px 55px 20px;
                border-top: 1px solid #e5e5e5;
                position: fixed;
                bottom: 0;
                width: 475px;
                z-index: 99;
                background-color: #fafbfa;
                button.Publish {
                    display: block;
                }
            }
            .MockupImagePreviewContainer {
                .ListImagePreview {
                    gap: 10px;
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    .PreviewImageItem {
                        position: relative;
                        border: 1px solid #d8dbde;
                        border-radius: 4px;
                        cursor: pointer;
                        min-width: 78px;
                        min-height: 78px;
                        transition: 0.3s all;
                        .lightui1 {
                            padding: 0;
                            .lightui1-shimmer {
                                height: 78px;
                                border-radius: 4px;
                            }
                        }
                        @media (max-width: 1400px) {
                            .lightui1 {
                                .lightui1-shimmer {
                                    height: 78px;
                                }
                            }
                        }
                        .background {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                        }
                        .Mask {
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            z-index: 5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            border-top: solid 15px #007bff;
                            border-right: solid 15px #007bff;
                            border-left: solid 15px transparent;
                            border-bottom: solid 15px transparent;
                            .Icon {
                                border-radius: 50%;
                                color: #fff;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                left: 50%;
                                top: -12px;
                            }
                        }
                        &:hover {
                            opacity: 0.7;
                        }
                        &.active {
                            box-shadow: 0 0 0 2px #007bff;
                        }
                        img {
                            width: 100%;
                            object-fit: cover;
                            position: relative;
                            z-index: 2;
                        }
                    }
                }
                .LoadingContainer {
                    left: 50%;
                    top: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
        }
        &.AllAreaTab {
            height: auto;
            .CampaignDetailsContainer {
                max-height: 100%;
            }
        }
        .TabTitle {
            display: flex;
            align-items: baseline;
            font-weight: 700;
            border-bottom: 1px solid #d3dcd7;
            //border-top: 1px solid #D3DCD7;
            color: #a9afab;
            padding: 0 24px;

            .Title {
                position: relative;
                padding: 12px 8px;
                cursor: pointer;

                &.Design {
                    margin-right: 24px;
                }

                &.active {
                    color: #007bff;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #007bff;
                    }
                }
                &.disabled {
                    cursor: not-allowed;
                }
            }
        }

        .CampaignDesignContainer {
            .LoadingContainer {
                width: 280px;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                .Percentage {
                    span {
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 36px;
                    }
                    .progress {
                        height: 7px;
                    }
                }
                .LoadingText {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .MoreCampaign {
                margin-right: 5px;
                display: inline-block;
            }

            .BulkUploadButtonContainer {
                display: inline-block;

                button {
                    font-size: 14px;
                    background-color: transparent;
                    border-radius: 4px;
                    padding: 0 42px;
                    font-weight: bold;
                    color: #2f3230;
                    height: 44px;
                    line-height: 42px;
                    border: 1px solid #c0c1c0;

                    svg {
                        margin-right: 5px;
                        color: #2f3230;
                    }
                }
            }
        }

        .ArtworkRequirement {
            padding: 32px 24px 24px 24px;

            .Heading {
                color: #2f3230;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 8px;
            }

            .Content {
                font-size: 16px;
                color: #6e7370;

                .Value {
                    font-weight: 700;
                }
            }
        }
    }

    button.MoreCampaign {
        display: inline-flex;
        align-items: center;
        background-color: #007bff;
        color: #fff;
        font-size: 14px;
        outline: none;
        border: 1px solid #007bff;
        border-radius: 4px;
        padding: 0 16px;
        height: 44px;
        line-height: 42px;
        margin: 0 24px;
        transition: all 0.3s;

        &:hover {
            background-color: darken(#007bff, 10);
            border-color: darken(#007bff, 10);
        }

        svg {
            margin-right: 6px;
        }
    }

    .CampaignList {
        // padding-top: 10px;
        // max-height: 650px;
        overflow-y: auto;
    }

    .CampaignDesignContainer {
        .CampaignItemTitle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
        }
        .CampaignItem {
            position: relative;
            padding: 18px;
            background-color: #fff;
            // max-height: calc(100vh - 220px);
            // border-top: 1px solid #dee2e6;

            .card {
                &.active {
                    border: 1px solid #007bff;
                }
            }
            .HeaderLeft {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 75%;
            }

            .HeaderRight {
                i {
                    cursor: pointer;
                    font-size: 18px;
                }
            }

            .CampaignTitle {
                margin-bottom: 12px;
                cursor: pointer;
                .IconRemove {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    display: inline-flex;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #dc3545;
                    }
                }

                .InputTitle {
                    position: relative;
                    width: 100%;
                    padding: 8px 16px;
                    border: 1px solid #ced4da;
                    border-radius: 4px;
                    transition: all 0.3s;

                    &:focus {
                        border: 1px solid #007bff;
                    }
                }

                .Title {
                    font-weight: 700;

                    .IconEdit {
                        margin-right: 8px;
                        cursor: pointer;
                        vertical-align: text-bottom;
                    }
                }

                &.invalid {
                    .InputTitle {
                        border: 1px solid #dc3545;
                    }
                }
            }

            .CampaignItemAction {
                svg {
                    cursor: pointer;
                }
            }

            .Sides {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 32px;
            }

            .CampaignUpload {
                > .Wrapper {
                    max-width: 100%;
                }

                .PFBoxUploader {
                    border: 2px dashed #6e7370;
                    border-radius: 4px;

                    .InputUpload {
                        transition: all 0.3s;

                        &:hover {
                            background-color: darken(#ffffff, 5);
                        }
                    }
                }

                .Side {
                    text-align: center;
                    color: #a9afab;
                    font-size: 12px;
                    margin-top: 4px;
                }

                .InputUpload {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    position: relative;
                    padding: 0 0 100% 0;
                }

                .IconUpload {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }

                .Preview {
                    position: relative;
                    padding: 0 0 100% 0;

                    .Image {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: 15px;
                        border: 2px solid #6e7370;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    .IconRemove {
                        position: absolute;
                        right: -10px;
                        top: -10px;
                        display: inline-flex;
                        color: #dc3545;
                        cursor: pointer;
                    }

                    .Invalid {
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        color: #fff;
                        background-color: rgba(220, 53, 69, 0.6);
                        font-size: 14px;
                        text-transform: capitalize;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        border-radius: 4px;
                    }
                }

                &.active {
                    .Side {
                        color: #2f3230;
                    }

                    .Preview .Image {
                        border: 2px solid #007bff;
                    }
                }

                &:hover {
                    opacity: 0.7;
                }

                &.invalid-artwork {
                    .Preview .Image {
                        border: 2px solid #dc3545;
                    }
                }

                &.missing-artwork {
                    .PFBoxUploader {
                        border: 2px dashed #dc3545;
                    }
                }

                &.uploading {
                    .Preview .Image {
                        border: 2px dashed #6e7370;
                    }
                }

                &.disabled {
                    pointer-events: none;

                    .PFBoxUploader {
                        opacity: 0.65;
                    }
                }

                .Progress {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(47, 50, 48, 0.4);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    border-radius: 4px;

                    .progress {
                        height: 7px;
                        width: 60px;
                        max-width: 100%;
                    }

                    .progress-bar {
                        background-color: #007bff;
                    }
                }
            }

            &.invalid {
                background-color: #f9e3e5;
            }

            .CampaignItemFooter {
                .AddImageSection,
                .AddTextSection {
                    label {
                        width: 100%;
                        cursor: pointer;
                        text-align: center;
                        padding: 10px 12px;
                        border: 1px dashed #dee2e6;
                        border-radius: 0.25rem;
                        &.DisabledLabel {
                            color: #b0b7bf;
                            cursor: not-allowed;
                            border: 1px dashed #b0b7bf;
                            background-color: #f8f9fa;
                        }
                    }
                }
            }
        }

        .UploadWrapper {
            position: relative;

            .Sides {
                opacity: 0;

                &.active {
                    opacity: 1;
                }
            }

            .Unlink {
                position: absolute;
                top: 0;
                width: 100%;

                &:not(.active) {
                    z-index: -1;
                }
            }
        }
    }

    .CampaignDetailsContainer {
        padding: 0 24px;

        .Section {
            .Heading {
                font-weight: 700;
                color: #2f3230;
                margin-bottom: 8px;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &.Tracking {
                .Title {
                    font-weight: 700;
                    color: #2f3230;
                    margin-bottom: 8px !important;
                    font-size: 14px;
                }
            }
        }

        .rdw-editor-toolbar {
            border: 1px solid #d3dcd7;
            margin-bottom: 0;
            background-color: #f3f5f4;

            a {
                color: #000;
            }
        }

        .rdw-option-wrapper {
            background-color: transparent;
            height: 25px;
            padding: 0 !important;
            margin: 0 4px !important;
            min-width: 20px !important;
        }

        .rdw-dropdown-wrapper {
            background-color: transparent;

            &:hover {
                box-shadow: 1px 1px 0 #bfbdbd;
            }
        }

        .EditorApp {
            border-color: #d3dcd7;
            border-style: solid;
            border-width: 0 1px 1px 1px;
            border-radius: 0 0 4px 4px;
            background-color: #fff;
            padding: 0 12px;
            height: 150px;
        }

        .CampaignTags {
            input[type='text'] {
                &::placeholder {
                    font-size: 14px;
                }
            }

            .badge {
                font-size: 12px;
            }
        }

        .CampaignDisplay {
            .custom-control {
                z-index: 0;
            }
        }

        .CampaignDescription {
            &.invalid {
                .EditorToolbar,
                .EditorApp {
                    border-color: #dc3545;
                }

                .EditorToolbar {
                    border-bottom-color: #d3dcd7;
                }
            }
        }

        .CollectionPixelIds {
            padding-left: 16px;
            margin-top: 8px;

            .Label {
                font-weight: 500;
            }

            ul.List {
                list-style: initial;
                padding-left: 16px;

                li {
                    list-style: initial;
                }
            }
        }
    }

    .NavBar2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 32px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
        position: relative;
        z-index: 1;

        .Progress {
            display: flex;
            align-items: baseline;
            justify-content: center;
            font-size: 14px;
            color: #6e7370;

            .Step {
                position: relative;
                min-width: 256px;
                color: #989b9f;
                display: flex;
                align-items: center;
                justify-content: center;
                .StepNumber {
                    width: 35px;
                    height: 35px;
                    border: 1px solid #989b9f;
                    text-align: center;
                    line-height: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                &::after {
                    width: 90px;
                    height: 1px;
                    display: block;
                    background-color: #dee2e6;
                    position: absolute;
                    top: 50%;
                    right: -45px;
                    content: '';
                }
                &.done {
                    color: #303238;
                    &::after {
                        background-color: #007bff;
                    }
                    .StepNumber {
                        border: 1px solid #007bff;
                    }
                }

                &.current {
                    color: #007bff;
                    &::after {
                        width: 100px;
                        right: -65px;
                    }
                    .StepNumber {
                        color: #ffffff;
                        border: 1px solid #007bff;
                        background-color: #007bff;
                    }
                }

                &:last-child::after {
                    display: none;
                }
            }
        }

        .Left {
            .PrevStep {
                display: inline-block;
                color: #5c60c4;
                cursor: pointer;
                font-size: 14px;
                font-weight: 700;

                .Icon {
                    margin-right: 12px;
                    vertical-align: text-bottom;
                }
            }
        }
    }

    .PreviewArtworkContainer {
        .carousel-indicators li {
            background-color: #2f3230;
        }
    }

    .CountCompleted {
        background-color: #e3f2eb;
        border-radius: 4px;
        display: flex;
        padding: 10px 16px;
        margin-bottom: 16px;

        .IconCheck {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            background-color: #007bff;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            color: #fff;
        }

        .Text {
            font-size: 14px;
            font-weight: 700;
            color: #2f3230;
        }
    }

    .Step2Container {
        > .Wrapper {
            display: flex;
            height: calc(100vh - 68px);

            > .Left {
                width: 400px;
                background: #fafbfa;
                border-right: 1px solid #d3dcd7;
                flex-shrink: 0;

                #btn-collapse-left {
                    position: absolute;
                    top: 50%;
                    right: -16px;
                    transform: translateY(-50%);
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    padding: 4px;
                    text-align: center;
                    background-color: #eaeef1;
                    cursor: pointer;
                    transition: 0.3s;
                    z-index: 10;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            > .Center {
                flex-grow: 1;
                //padding: 24px;
                &.NewVersion {
                    // margin: auto;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .PreviewArtworkContainer {
                        height: calc(100vh - 138px);
                    }
                    .mockup-loading {
                        border-top: 1px solid rgba(0, 0, 0, 0.125);
                    }
                    .ProductVariantPricingContainer {
                        overflow-y: scroll;
                        height: calc(100vh - 60px);
                        .ProductVariantPricingEdit {
                            .PricingEdit {
                                label {
                                    width: 100px;
                                    margin-bottom: 0;
                                }
                                input {
                                    min-width: 250px;
                                }
                            }
                        }
                    }
                }

                .InfoText {
                    font-size: 14px;
                    margin: 16px 0;
                    color: #007bff;
                }

                > .Top {
                    margin: 40px 24px 0;

                    .SwitchBetweenDesignAndPreview {
                        margin-left: auto;
                    }

                    .LinkArtwork {
                        margin-left: 16px;
                    }
                }

                > .Bottom {
                    display: flex;
                    // height: calc(100vh - 220px);
                    overflow: hidden;

                    .PreviewArtworkContainer {
                        flex-grow: 1;
                        padding: 24px;
                    }

                    .EditorToolbarContainer {
                        margin-top: 24px;
                        margin-right: 24px;
                        flex-shrink: 0;
                    }
                }
                .MockupEditor {
                    // border-bottom: 1px solid #e5e5e5;
                    position: relative;
                    height: 100%;
                    height: calc(100vh - 138px);
                    .konvajs-content {
                        margin: 0 auto;
                    }
                }
                .MockupBottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 70px;
                    padding: 0 20px;
                    background-color: #f8f9fa;
                    box-shadow: 0 -1px 0 0 #dee2e6;
                    .MockupDrag,
                    .MockupBackgroundColor {
                        cursor: pointer;
                        padding: 6px 12px;
                        border-radius: 4px;
                        background-color: #ffffff;
                        border: 1px solid #dee2e6;
                        &.Active {
                            svg {
                                stroke: #b5b5b5;
                            }
                            background-color: #d5d5d5;
                        }
                        .MockupBackgroundColorIcon {
                            display: inline-block;
                            vertical-align: middle;
                            padding: 6px 0;
                        }
                        .CboApplyAllPlacements {
                            display: inline-flex;
                            vertical-align: middle;
                            border-left: 1px solid #d8dbde;
                            line-height: 16px;
                            padding-left: 12px;
                            align-items: center;
                            margin-left: 12px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                            input {
                                margin-right: 6px;
                            }
                        }
                    }
                    .MockupBackgroundColor {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    .MockupGuideline {
                        cursor: pointer;
                        border-radius: 50%;
                        padding: 6px 8px;
                        background-color: #007bff;
                        border: 1px solid #007bff;
                    }
                    .MockupUndo {
                        .btn {
                            border-color: #ced4da;
                            &:hover {
                                border-color: #adadad;
                                background-color: #e6e6e6;
                            }

                            &:disabled {
                                opacity: 0.5;
                            }
                        }
                    }
                    .pulse {
                        animation: pulse-animation 2s infinite;
                    }

                    @keyframes pulse-animation {
                        0% {
                            box-shadow: 0 0 0 0px #007bff;
                        }
                        100% {
                            box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
                        }
                    }
                    .MockupZoom {
                        .btn-minus {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            border-right: unset;
                        }
                        .TextRatio {
                            width: 70px;
                            background-color: #fff;
                            text-align: center;
                        }
                        .btn-plus {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-left: unset;
                        }
                    }
                }
                > .PreviewArtworkDownload {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 70px;
                    padding: 0 20px;
                    box-shadow: 0 -1px 0 0 #dee2e6;
                }
                &.NewVersion {
                    > .Top {
                        margin: 17px 20px 0;
                    }
                }
            }

            > .Right {
                width: 473px;
                border-left: 1px solid #d3dcd7;
                background-color: #fafbfa;
                flex-shrink: 0;
            }
            .Left {
                &.toggle {
                    width: 150px;
                    #btn-collapse-left {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                    #btn-collapse-right {
                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    .ListProductLineConfirm {
                        .SubAddMoreProduct {
                            display: block;
                        }
                    }
                }
            }
            .Left {
                &.toggle {
                    .HeaderListProductLine {
                        display: none;
                    }
                    .ContentListProductLine {
                        padding: 32px 12px 32px 12px;
                        .ItemProductLine {
                            .Img {
                                width: 96px;
                            }
                            .HeaderItem .Title,
                            .HeaderItem .TopRight,
                            .collapse {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .BadgeStatus {
        padding: 0 16px;
        font-size: 12px;
        color: #fff;
        border-radius: 99px;
        line-height: 20px;
        display: inline-block;

        &.Error {
            background-color: #dc3545;
        }

        &.Completed {
            background-color: #007bff;
        }

        &.Pending {
            background-color: #47b2c1;
            animation: progress-bar-stripes 1s linear infinite;
            background-image: linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.15) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.15) 50%,
                rgba(255, 255, 255, 0.15) 75%,
                transparent 75%,
                transparent
            );
            background-size: 1rem 1rem;
        }

        &.Uploading {
            background-color: #5c60c4;
            animation: progress-bar-stripes 1s linear infinite;
            background-image: linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.15) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.15) 50%,
                rgba(255, 255, 255, 0.15) 75%,
                transparent 75%,
                transparent
            );
            background-size: 1rem 1rem;
        }
    }

    button.ViewAllProducts {
        background-color: #007bff;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 700;
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        border-radius: 4px;
        border: none;
        margin: 0;
        transition: all 0.3s;

        &:hover {
            background-color: darken(#007bff, 10);
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.6;
        }
    }

    &.ModalPublish {
        width: 640px;
        max-width: calc(100vw - 30px);

        .DivAlignContentCenter {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
        }

        .NameProductPublish {
            font-size: 14px;
            color: #5c60c4;
        }

        .FeatureImageProductPublishOpacity {
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
        }

        .DisabledCloseModalHeader {
            .close:hover {
                color: #000;
                cursor: default;
                opacity: 0.5;
            }
        }

        .DisabledButtonViewAllProducts:hover {
            cursor: default;
        }

        .modal-title {
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 500;
            color: #2f3230;
        }

        .modal-body {
            padding: 18px 24px;
        }

        .Campaign {
            &:not(:last-child) {
                padding-bottom: 16px;
                margin-bottom: 16px;
                border-bottom: 1px solid #e9eeec;
            }

            .Wrapper {
                display: flex;
                align-items: center;
                min-height: 50px;
            }

            .Left {
                display: flex;
                width: 106px;
                margin-right: 24px;
            }

            .Center {
                margin-right: 24px;
            }

            .Right {
                margin-left: auto;
                display: flex;

                .MockupImage {
                    display: inline-flex;
                    border: 1px solid #d3dcd7;
                    border-radius: 4px;
                    overflow: hidden;
                    position: relative;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }

                    .CountImg {
                        position: absolute;
                        background: #000000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        color: #ffffff;
                        z-index: 9;
                        opacity: 0.6;
                        border-radius: 4px;
                        font-weight: bold;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
            }

            .MessageError {
                font-size: 14px;
            }
        }

        .modal-header {
            padding: 32px 24px 16px;
            border-bottom: 1px solid #e9eeec;
            position: relative;

            .IconClose {
                position: absolute;
                top: 13px;
                right: 13px;
                cursor: pointer;
                display: inline-flex;
                transition: all 0.3s;

                &:hover {
                    opacity: 0.75;
                }
            }
        }

        .modal-footer {
            border-top: 1px solid #e9eeec;
            padding: 16px 24px 24px;
            a {
                &.disabled {
                    pointer-events: none;
                }
            }
        }

        &.creating {
            .modal-header {
                .IconClose {
                    cursor: not-allowed;
                }
            }
        }
    }

    .DPIArea {
        text-align: center;
        min-height: 40px;
        margin-bottom: 24px;

        .ArtworkDPI {
            border-radius: 4px;
            display: inline-block;
            padding: 9px 10px;

            .Icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                border-radius: 50%;
                text-align: center;
                line-height: 16px;
                margin-right: 8px;
                vertical-align: bottom;
            }

            .Text {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
            }

            &.invalid {
                background-color: #f9e3e5;

                .Icon {
                    background-color: #dc3545;
                    color: #fff;
                }
            }

            &:not(.invalid) {
                background: #e3f2eb;

                .Icon {
                    background-color: #007bff;
                    color: #fff;
                }
            }
        }
    }

    .ImageDPI {
        display: flex;
        align-items: center;
        .Icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 8px;
        }

        .Text {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
        }

        &.invalid {
            .Icon {
                background-color: #dc3545;
                color: #fff;
            }
            .Text {
                color: #dc3545;
            }
        }

        &:not(.invalid) {
            .Icon {
                background-color: #007bff;
                color: #fff;
            }

            .Text {
                color: #007bff;
            }
        }
        &.BottomImageDpi {
            padding: 8px;
            border-radius: 4px;
            margin-right: 0.5rem;
            &.invalid {
                background-color: #f9e3e5;
            }
            &:not(.invalid) {
                background-color: #e3f2eb;
            }
        }
    }

    &.PopoverPublish {
        .popover {
            width: 316px;
            max-width: 100vw;
        }

        .ErrorList {
            padding: 16px;
            background-color: #2f3230;
            color: #fff;
            font-size: 12px;
            border-radius: 4px;
        }

        .bs-popover-auto[x-placement^='bottom'] {
            & > .arrow {
                top: calc(-0.5rem - -4px);

                &::after {
                    border-bottom-color: #2f3230;
                }

                &::before {
                    border-bottom-color: #2f3230;
                }
            }
        }

        .ErrorList {
            .Error {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }
    }

    @media all and (min-width: 600px) {
        .col-half-right {
            padding-right: 5px;
        }

        .col-half-left {
            padding-left: 5px;
        }
    }

    .PreviewArtworkSlider {
        width: 600px;
        max-width: 100%;
        .slick-list {
            overflow: visible;
        }
        //margin: auto;
        .PreviewArtworkErrorWrapper {
            .PreviewArtworkError {
                width: 600px;
                height: 600px;
                background: #f6f6f6;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    cursor: pointer;
                }
            }
        }
        .Loading {
            text-align: center;
        }

        .slick-dots {
            bottom: 15px;
        }

        .slick-arrow {
            color: #a9afab;
            left: 50%;
            z-index: 1;
            &.slick-prev {
                transform: translateX(-330px);
            }
            &.slick-next {
                transform: translateX(310px);
            }
        }
        .Image {
            min-height: 600px;
            position: relative;
            .ImageFirstLoading {
                top: 0;
                left: 0;
                width: 100%;
                align-items: center;
                height: 100%;
                position: absolute;
                justify-content: center;
                display: flex;
                /* transform: translate(-50%, -50%); */
                background-color: #ffffff;
                &.loadingBackground {
                    z-index: 1;
                }
            }
            img {
                width: 100%;
            }
            .bgImage {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
                z-index: 1;
            }
        }
        @media (max-width: 1400px) {
            width: 400px;
            .PreviewArtworkErrorWrapper {
                .PreviewArtworkError {
                    width: 400px;
                    height: 400px;
                }
                .Image {
                    min-height: 400px;
                }
            }
        }
    }

    .ContentListProductMockup {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 25px;
        &.BoardContainer {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 20px;
        }
    }

    .Nav1 {
        button.SelectProducts {
            background-color: #007bff;
            border: none;
            color: #fff;
            border-radius: 4px;
            height: 44px;
            line-height: 42px;
            font-size: 14px;
            font-weight: 600;
            padding: 0 20px;
            transition: all 0.3s;

            &:hover {
                background-color: darken(#007bff, 10);
            }

            &.disabled {
                opacity: 0.65;
                cursor: not-allowed;
                a {
                    pointer-events: none;
                }
            }
        }
    }

    &.ModalListProductLine {
        .ContentListProductMockup {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    &.AdvancePricingByVariants {
        .ButtonSubmit {
            background-color: #007bff;
            border: none;
            color: #fff;
            border-radius: 4px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 600;
            padding: 0 20px;
            transition: all 0.3s;

            &:hover {
                background-color: darken(#007bff, 10);
            }
        }

        .ButtonCancel {
            border: 1px solid #e9eeec;
            border-radius: 4px;
            background-color: #fff;
            color: #2f3230;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 700;
            padding: 0 24px;
            transition: all 0.3s;

            &:hover {
                background-color: darken(#ffffff, 10);
                border-color: darken(#ffffff, 10);
            }
        }

        .modal-footer {
            border-top: 1px solid #e9eeec;
        }

        .modal-header {
            border-bottom: 1px solid #e9eeec;
        }

        .modal-title {
            font-size: 18px;
            color: #2f3230;
            font-weight: 500;
        }
    }

    .ArtworkEditor2 {
        > .Top {
            margin-bottom: 24px;
        }
    }

    img.PrintArea {
        position: relative;
        z-index: 2;
        pointer-events: none;
        max-height: calc(100vh - 350px);
    }

    &.EditorTool {
        .tooltip {
            pointer-events: none;
        }
    }

    &.ModalPublish {
        color: #2f3230;
        font-size: 14px;
        max-width: 640px;

        .modal-header {
            position: relative;
            padding: 32px 24px 16px;

            .modal-title {
                font-size: 18px;
            }

            .Icon {
                position: absolute;
                right: 13px;
                top: 13px;
                cursor: pointer;
                line-height: 1;
                transition: all 0.3s;

                &:hover {
                    opacity: 0.75;
                }

                &.disabled {
                    cursor: not-allowed;
                    color: inherit;
                }
            }
        }

        .VariantImages {
            display: flex;

            .Image {
                border: 1px solid #d3dcd7;
                border-radius: 4px;
                overflow: hidden;
                position: relative;

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }

            .Rest {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .CampaignItems {
            max-height: 70vh;
            overflow-y: auto;
        }
    }

    &.ModalPublishSplitContainer {
        .CampaignItem {
            border: 1px solid #f3f5f4;
            border-radius: 4px;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            .Toggle {
                display: flex;
                align-items: center;
                background-color: #e9eeec;
                padding: 12px;
                border-radius: 4px;
                cursor: pointer;

                .Title {
                    font-size: 16px;
                    font-weight: 500;
                    margin-right: 16px;
                }

                .Icon {
                    margin-left: auto;
                    display: inline-flex;
                }
            }

            .ProductLines {
                padding: 8px 12px;
            }
        }

        .ProductLineItem {
            &:not(:last-child) {
                margin-bottom: 8px;
            }

            > .Wrapper {
                display: flex;
                align-items: center;
                min-height: 50px;

                > .Left {
                    width: 100px;
                    margin-right: 28px;
                }

                > .Center {
                    margin-right: 16px;

                    a {
                        color: #5c60c4;
                    }
                }

                > .Right {
                    margin-left: auto;
                }
            }
        }
    }

    &.ModalPublishCombineContainer {
        .CampaignItem {
            &:not(:last-child) {
                margin-bottom: 8px;
            }

            > .Wrapper {
                display: flex;
                align-items: center;
                min-height: 50px;

                > .Left {
                    width: 100px;
                    margin-right: 28px;
                }

                > .Center {
                    margin-right: 16px;

                    a {
                        color: #5c60c4;
                    }
                }

                > .Right {
                    margin-left: auto;
                }
            }
        }
    }

    .LoadingWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
    }

    .LinkArtwork {
        font-size: 14px;
        color: #6e7370;
        text-transform: capitalize;

        .Status {
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            .Text {
                margin-left: 6px;
            }
        }
    }
    div[id^='font-picker'] {
        width: 150px !important;
        box-shadow: none !important;
        .dropdown-button {
            background: #ffffff !important;
            border: 1px solid #ced4da;
            border-radius: 4px !important;
            height: 38px !important;
            .dropdown-font-family {
                white-space: nowrap;
                overflow: hidden;
            }
            &:focus {
                background: #ffffff !important;
            }
        }
        ul li button {
            background-color: #ffffff !important;
            &:hover,
            &:focus {
                background-color: #dddddd !important;
            }
        }
    }
    button.Publish {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 4px;
        padding: 0 20px;
        height: 44px;
        line-height: 44px;
        display: inline-flex;
        align-items: center;
        transition: all 0.3s;

        &:hover {
            background-color: darken(#007bff, 10);
        }

        .Icon {
            margin-left: 8px;
        }
    }
    .skeleton-loading {
        width: auto;
        height: 600px;
    }
}
.CampaignDesignItem {
    #accordion {
        .card {
            margin: 10px 0;
            .card-header {
                padding: 0.75rem;
                background-color: #ffffff;
                color: #303238;
                // border-radius: 0.5rem;
                // border-bottom: unset;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
                span,
                svg {
                    cursor: pointer;
                }
                svg {
                    transition: 0.3s ease-out;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .header-right {
                    .lock {
                        cursor: not-allowed;
                    }
                }
            }
            .card-body {
                .Effects {
                    .accordion-body,
                    .accordion-button {
                        padding: 0.5rem 0.8rem;
                    }
                    .accordion-body {
                        .DropShadowInputWrapper,
                        .StrokeInputWrapper {
                            position: relative;
                            input {
                                padding-right: 0.25rem;
                            }
                            #DropShadowColorPicker,
                            #StrokeColorPicker {
                                top: 50%;
                                left: 6px;
                                z-index: 10;
                                position: absolute;
                                transform: translateY(-50%);
                                .Icon {
                                    width: 28px;
                                    height: 28px;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
                                }
                            }
                        }
                    }
                }
            }
            .LayerIcon {
                cursor: pointer;
            }
            .collapse {
                height: 0;
                overflow: hidden;

                &.show {
                    height: 100%;
                    transition: max-height 0.2s ease-out;
                }
                .card-body {
                    .input-group-text {
                        color: #d8dbde;
                        background-color: #f8f9fa;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-left: unset;
                        &.deg-group-text {
                            padding: 0.375rem 0.325rem;
                        }
                    }
                    .TextDesign {
                        #font_selector {
                            label {
                                cursor: pointer !important;
                            }
                        }
                    }
                }
                .btn-group {
                    width: 100%;
                    .btn {
                        border-color: #ced4da;
                        background-color: #ffffff;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
                .LayerIcon {
                    width: 20px;
                    height: 20px;
                }
                #color_pick_text {
                    .Icon {
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                        border-radius: 4px;
                        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
    .FontDisabled {
        .fp-select,
        #color_pick_text {
            pointer-events: none;
        }
        .fp-select {
            background-color: #e9ecef;
        }
    }
}
.MockupEditorToolbar {
    z-index: 0;
    position: absolute;
    padding: 6px;
    justify-content: center;
    align-items: center;
    display: none;
    width: auto;
    background-color: white;
    box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    &.TextToolbar {
        color: #3c3e44;
        .TextFontFamily {
            min-width: 150px;
        }
    }
    .btn-group {
        .btn {
            color: #3c3e44;
            border-color: #ccc;

            &.active,
            &:hover {
                color: #3c3e44;
                background-color: #e6e6e6;
                border-color: #adadad;
            }
        }
    }
    #TextColorSelector {
        position: relative;
        .TextColor {
            width: 20px;
            height: 3px;
            bottom: 6px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
        }
    }
    .font-picker {
        width: 150px !important;
    }
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.InlineEditor {
    position: absolute;
    border: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: none;
    outline: none;
    resize: none;
}
.draggable-item {
    z-index: 999999;
}
.compact-picker {
    > div {
        > span {
            > div {
                border-radius: 50%;
                border: 1px solid #dee2e6;
            }
        }
    }
    input {
        width: 100% !important;
    }
}
.MockupGuidelineModal {
    ul {
        padding-left: 40px;
        margin-bottom: 1rem;
        list-style: disc;
        li {
            list-style: disc;
        }
    }
}

@media (max-width: 1600px) {
    .MockupGeneratorContainer .UploadContainer .CampaignDesignContainer .BulkUploadButtonContainer {
        margin: 0 !important;
    }
    .MockupGeneratorContainer .UploadContainer .CampaignDesignContainer .MoreCampaign {
        margin-right: 5px !important;
    }
    .MockupGeneratorContainer
        .UploadContainer
        .CampaignDesignContainer
        .BulkUploadButtonContainer
        button.ButtonBulkUpload {
        padding: 0 15px;
        margin-right: 15px;
    }
    .MockupGeneratorContainer .Step2Container > .Wrapper > div.Right {
        width: 375px;
    }
    .MockupGeneratorContainer {
        .UploadContainer {
            &.NewVersion {
                .MockupGeneratorPublish {
                    width: 375px !important;
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .MockupGeneratorContainer {
        .UploadContainer {
            height: calc(100vh - 70px);
            &.NewVersion {
                .MockupImagePreviewContainer {
                    .ListImagePreview {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
            }
        }
        .Step2Container {
            > .Wrapper {
                > .Center {
                    .MockupEditor {
                        height: calc(100vh - 134px);
                    }
                }
            }
        }
    }
}

.PopupCompactPicker {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px, rgba(0, 0, 0, 0.16) 0px 2px 5px;
    border-radius: 2px;
    background: rgb(255, 255, 255);
    border: none;
    max-width: 315px;
    padding: 10px;
    .compact-picker {
        width: 100% !important;
    }
    .popover-inner {
        > div:first-child {
            > div:first-child {
                opacity: 0;
            }
        }
    }
    .TextInfoColorPicker {
        font-size: 13px;
    }
}

.PricingEdit {
    .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

