.MockupGeneratorContainer {
    .Step2Container {
        > .Wrapper {
            > .Left {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
    @media (max-width: 1600px) {
        .NavBar2 {
            padding: 14px 32px;

            button.Publish {
                height: 40px;
                line-height: 40px;
            }
        }

        .Step2Container {
            > .Wrapper {
                > .Left {
                    width: 300px;
                    .ListProductLineConfirm {
                        .HeaderListProductLine {
                            padding: 15px 12px 0 12px;
                        }
                        .ContentListProductLine {
                            padding: 0 12px 12px 12px;
                        }
                    }
                }

                > .Center {
                    > .Top {
                        margin-top: 24px;
                    }

                    > .Bottom {
                        height: calc(100vh - 185px);
                    }
                }

                > .Right {
                    width: 352px;
                    // width: 335px !important;
                    //border-left: none;
                }
            }
        }

        .ListProductLineConfirm .HeaderListProductLine {
            padding-top: 24px;
        }

        .SwitchBetweenSides {
            button.Side {
                font-size: 14px;
            }
        }

        .SwitchBetweenDesignAndPreview {
            > button {
                font-weight: 400;
            }
        }

        .UploadContainer {
            padding-top: 0;

            .ArtworkRequirement {
                display: none;
            }

            .TabTitle {
                border-top: none;
            }

            .CampaignDesignContainer {
                .MoreCampaign {
                    margin-right: 24px;
                }

                .BulkUploadButtonContainer {
                    margin-left: 24px;
                    margin-top: 5px;
                    margin-right: 24px;
                }
            }
        }

        .CampaignItem {
            .Sides {
                grid-column-gap: 16px;
            }
        }

        .ListProductLineConfirm .ItemProductLine .HeaderItem .Title {
            font-size: 14px;
            font-weight: 700;
            margin-right: auto;
        }

        .ContentListProductMockup {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &:not(.modal-dialog) {
            .HeaderListProduct .RowHeaderListProduct {
                height: 68px;
            }
        }
    }

    @media (max-width: 996px) {
        .ContentListProductMockup {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media all and (min-width: 600px) {
        .col-half-right {
            padding-right: 5px;
        }

        .col-half-left {
            padding-left: 5px;
        }
    }
}

